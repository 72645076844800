import React from "react";
import {Container, Row, Col} from "react-bootstrap";
import {AiFillGithub, AiFillInstagram, AiOutlineTwitter} from "react-icons/ai";
import {FaLinkedinIn} from "react-icons/fa";


function Footer() {
    let date = new Date();
    let year = date.getFullYear();
    return (
        <Container fluid className="footer">
            <Row>
                <Col md="4" className="footer-copywright">
                    <h3>Design von Jan Heidel</h3>
                </Col>
                <Col md="4" className="footer-copywright">
                    <h3>Copyright © { year } JH</h3>
                </Col>
                <Col md="4" className="footer-body">
                    <ul className="footer-icons">
                        <li className="social-icons">


                            <li className="social-icons">
                                <a
                                    href="https://www.linkedin.com/in/jan-heidel-66a326303"
                                    style={ { color: "white" } }
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    <FaLinkedinIn/>
                                </a>
                            </li>
                        </li>
                    </ul>
                </Col>
            </Row>
        </Container>
    );
}

export default Footer;
